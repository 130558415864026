import { Box, Skeleton } from "@mui/material";
import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "../../common/context/AlertContext";
import { useCollective } from "../../common/context/CollectiveContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import { isBeforeCutoffToEditProductRequest } from "../../common/util/DatePickerUtilFrontend";

const AdvancedRequestFormContext = createContext();

const defaultAdvancedRequest = {
  orderReference: "",
  buyerBusiness: null,
  pickupDate: null,
  notes: "",
  pickupLocation: null,
  estimatedTotal: 0,
  fulfillmentStatus: "DRAFT",
  requestProducts: [],
  attachments: [],
};

export const AdvancedRequestFormContextProvider = ({ adminView, children }) => {
  const { collectiveInfo } = useCollective();
  const [advancedRequest, setAdvancedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addErrorAlert } = useAlerts();

  let isNew = false;
  let { requestId } = useParams();
  if (requestId) {
    requestId = parseInt(requestId);
  } else {
    isNew = true;
  }

  useEffect(() => {
    if (isNew) {
      setAdvancedRequest(defaultAdvancedRequest);
      setLoading(false);
    } else if (requestId && advancedRequest?.id !== requestId) {
      AdvancedRequestService.getAdvancedRequestById(requestId)
        .then(setAdvancedRequest)
        .catch((err) => addErrorAlert("Error fetching request", err))
        .finally(() => setLoading(false));
    } else if (!isNew && !requestId) {
      setAdvancedRequest(null);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  let canEdit = true;
  if (advancedRequest?.pickupDate) {
    const pickupDate = moment(advancedRequest?.pickupDate);
    canEdit =
      advancedRequest.fulfillmentStatus === "DRAFT" ||
      isBeforeCutoffToEditProductRequest(pickupDate, collectiveInfo);
  }

  return (
    <AdvancedRequestFormContext.Provider
      value={{
        adminView,
        advancedRequest,
        setAdvancedRequest,
        canEdit,
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={400} />
      ) : advancedRequest ? (
        children
      ) : (
        <Box sx={{ p: 2 }}>Request not found</Box>
      )}
    </AdvancedRequestFormContext.Provider>
  );
};

export const useAdvancedRequestContext = () =>
  useContext(AdvancedRequestFormContext);
