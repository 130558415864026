import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlerts } from "../../context/AlertContext";
import AutoSubmitOnChange from "../form/AutoSubmitOnChange";
import LoadingOverlay from "./LoadingOverlay";

const PaginatedSearchResults = forwardRef(
  (
    {
      fetchSearchResults,
      ResultDisplayComponent,
      defaultSearchCriteria,
      SearchCriteriaComponent,
      onClickSearchResult,
      normalizeSearchCriteria,
      validationSchema,
      navigateOnSearch = true,
      DownloadResultsComponent,
      // If you have multiple paginated search results on the same page, you can specify a unique name for the location state field
      locationStateFieldName = "lastSearchedCriteria",
      ...props
    },
    ref
  ) => {
    const locationState = useLocation().state;
    const lastSearchedCriteria =
      locationState && locationState[locationStateFieldName];
    const initSearchCriteria = lastSearchedCriteria || defaultSearchCriteria;
    const [searchCriteria, setSearchCrit] = useState({
      ...initSearchCriteria,
    });
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState();
    const formikRef = useRef(null);
    const { addErrorAlert } = useAlerts();
    const navigate = useNavigate();
    const scrollRef = useRef();

    useImperativeHandle(ref, () => ({
      // This can be called from parent components to trigger a re-run of the search
      rerunSearch: (newSearchCriteria) => {
        formikRef.current.setValues({
          ...searchCriteria,
          manualTrigger: Math.random(),
          ...(newSearchCriteria || {}),
        });
        scrollRef.current.scrollIntoView();
      },
    }));

    function setSearchCriteria(newCriteria) {
      // If the criteria that changes is NOT page number, reset the page to 1
      if (newCriteria.page !== searchCriteria.page) {
        setSearchCrit(newCriteria);
      } else {
        setSearchCrit({ ...newCriteria, page: 1 });
      }
    }

    useEffect(() => {
      setLoading(true);
      const normalizedSearchCriteria = normalizeSearchCriteria
        ? normalizeSearchCriteria(searchCriteria)
        : searchCriteria;
      if (navigateOnSearch) {
        navigate(undefined, {
          state: {
            [locationStateFieldName]: { ...normalizedSearchCriteria },
          },
          replace: true,
        });
      }
      fetchSearchResults({ ...normalizedSearchCriteria })
        .then((response) => {
          setSearchResults(response);
          setLoading(false);
        })
        .catch((err) => {
          addErrorAlert("Error completing request", err);
        });
      formikRef.current.resetForm({ values: searchCriteria });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteria, fetchSearchResults, navigate, addErrorAlert]);

    const numPagesTotal =
      searchResults?.length > 0
        ? Math.ceil(
            (1.0 * searchResults[0].totalCount) / searchCriteria.itemsPerPage
          )
        : undefined;

    const ResultPagination = ({
      itemsPerPageOptions = [
        {
          label: "10",
          value: 10,
        },
        {
          label: "25",
          value: 25,
        },
        {
          label: "50",
          value: 50,
        },
      ],
      sx,
      formik,
      ...props
    }) => {
      return (
        <Grid
          container
          justifyContent={"end"}
          alignItems="center"
          sx={{
            m: 1,
          }}
          {...props}
        >
          <Grid>
            <Pagination
              name="page"
              count={numPagesTotal}
              page={searchCriteria.page}
              onChange={(p, v) => {
                formik.handleChange({ target: { name: "page", value: v } });
              }}
              size="small"
              data-testid="pagination"
            />
          </Grid>
          {itemsPerPageOptions?.length > 1 && (
            <Grid>
              <FormControl fullWidth size={"small"}>
                <InputLabel id={"itemsPerPage-label"}># per page</InputLabel>
                <Select
                  labelId={props.name + "-label"}
                  name="itemsPerPage"
                  label="# per page"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  value={formik.values["itemsPerPage"]}
                  style={{ width: 100 }}
                  data-testid="items-per-page-select"
                >
                  {itemsPerPageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      );
    };

    return (
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
          ...props.sx,
        }}
        ref={ref}
        data-testid="paginated-search-results"
      >
        <Formik
          onSubmit={setSearchCriteria}
          initialValues={initSearchCriteria}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {({ resetForm, touched, dirty }) => {
            return (
              <Form style={{ position: "relative" }} data-testid="search-form">
                {/* When the form changes, automatically submit the search */}
                <AutoSubmitOnChange />
                {Boolean(SearchCriteriaComponent) && (
                  <SearchCriteriaComponent
                    clearFilters={() => {
                      setSearchCriteria({
                        ...defaultSearchCriteria,
                        dirtyFlag: 1,
                      });
                    }}
                  />
                )}
                {Boolean(DownloadResultsComponent) && (
                  <DownloadResultsComponent
                    searchCriteria={searchCriteria}
                    searchResults={searchResults}
                  />
                )}
                {formikRef.current && (
                  <ResultPagination
                    sx={{ mb: 0.5, mt: 0.5 }}
                    {...props.PaginationProps}
                    formik={formikRef.current}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
        <Box
          id="search-results"
          {...props.SearchResultsProps}
          sx={{ position: "relative", ...props.SearchResultsProps?.sx }}
          data-testid="search-results-box"
          ref={scrollRef}
        >
          {searchResults?.length > 0 ? (
            <>
              {loading && <LoadingOverlay />}
              {searchResults.map((item, idx) => (
                <ResultDisplayComponent
                  key={item.id + `-${idx}`}
                  item={item}
                  onClick={
                    onClickSearchResult
                      ? () => onClickSearchResult(item)
                      : undefined
                  }
                />
              ))}
            </>
          ) : searchResults?.length === 0 ? (
            <Typography
              data-testid="no-results"
              sx={{ fontSize: "130%", textAlign: "center" }}
            >
              No results found
            </Typography>
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ m: 0.25 }}
                data-testid="skeleton-1"
              />
              <Skeleton
                variant="rectangular"
                sx={{ m: 0.25 }}
                data-testid="skeleton-2"
              />
              <Skeleton
                variant="rectangular"
                sx={{ m: 0.25 }}
                data-testid="skeleton-3"
              />
            </>
          )}
        </Box>
        {Boolean(formikRef.current) && (
          <ResultPagination
            sx={{ mt: 0.5 }}
            {...props.PaginationProps}
            formik={formikRef.current}
          />
        )}
      </Box>
    );
  }
);

export default PaginatedSearchResults;
