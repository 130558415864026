import { Add, Download } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment-timezone";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import SelectBusinessDialog from "../../../common/components/dialogs/SelectBusinessDialog";
import AutoSubmitOnChange from "../../../common/components/form/AutoSubmitOnChange";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import {
  getAttributesText,
  ProductInstanceSummary,
} from "../../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import ProductSaleFocusDisplay from "../../../common/components/product/ProductSaleFocusDisplay";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import { useCollective } from "../../../common/context/CollectiveContext";
import StockLogService from "../../../common/service/StockLogService";
import { isValidBringToMarketDay } from "../../../common/util/DatePickerUtilFrontend";

function mapProductToFlatCsvFields(product) {
  return {
    Product: product.name,
    Attributes: getAttributesText(product.attributes),
    Quantity: product.quantity,
    "Sale Focus": product.saleFocus,
  };
}

const BringToMarket = () => {
  const { state } = useLocation();
  const { addErrorAlert } = useAlerts();
  const [products, setProducts] = useState([]);
  const { defaultSupplier, supplierBusinesses, setDefaultSupplier } =
    useAuthContext();
  const [supplier, setSupplier] = React.useState(defaultSupplier || null);
  const [loading, setLoading] = React.useState(false);
  const [supplierDialogOpen, setSupplierDialogOpen] = React.useState(false);
  const formikRef = React.useRef();
  const { collectiveInfo } = useCollective();
  const [dateForResults, setDateForResults] = React.useState();

  const fetchProducts = React.useCallback(
    async (marketDate) => {
      setLoading(true);
      StockLogService.getStockLogs({
        supplierId: supplier.id,
        dateStocked: marketDate.format("YYYY-MM-DD"),
        status: "SUBMITTED",
        orderBy: "BringToMarket",
      })
        .then(setProducts)
        .then(() => {
          setDateForResults(marketDate);
        })
        .catch((error) => addErrorAlert("Error fetching products", error))
        .finally(() => setLoading(false));
    },
    [addErrorAlert, supplier?.id]
  );

  const validationSchema = Yup.object({
    marketDate: Yup.date()
      .required("Market date is required")
      .typeError("Invalid date")
      .test("is-valid-bring-to-market-day", "Invalid market date", (value) =>
        isValidBringToMarketDay(value, collectiveInfo)
      )
      // Only allow dates after 2000
      .test("4-digit-year-required", "Invalid Date", (value) => {
        return value ? moment(value).year() > 1000 : true;
      })
      .nullable(),
  });

  React.useEffect(() => {
    if (formikRef.current?.values.marketDate && supplier?.id) {
      fetchProducts(formikRef.current.values.marketDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  return (
    <Container>
      <Box sx={{ maxWidth: 600, marginRight: "auto", marginLeft: "auto" }}>
        <Typography
          variant="h4"
          gutterBottom
          data-testid="bring-to-market-title"
        >
          Bring to Market
        </Typography>
        <Formik
          initialValues={{
            marketDate: state?.marketDate ? moment(state?.marketDate) : null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            fetchProducts(values.marketDate);
          }}
          innerRef={formikRef}
        >
          {({ values }) => (
            <Form autoComplete="off">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  marginBottom: 3,
                  flexWrap: "wrap",
                }}
              >
                <AutoSubmitOnChange />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "250px",
                  }}
                >
                  <Field
                    name="marketDate"
                    component={FormDatePicker}
                    label="Market Date"
                    autoFocus
                    disablePast
                    shouldDisableDate={(d) =>
                      !isValidBringToMarketDay(d, collectiveInfo)
                    }
                  />
                </Box>
                {supplier && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                  >
                    <Avatar
                      src={supplier.logoPath}
                      sx={{ height: 36, width: 36, mr: 2 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="body1">{supplier.name}</Typography>
                      {supplierBusinesses.length > 1 && (
                        <Link
                          variant="caption"
                          component="button"
                          onClick={() => setSupplierDialogOpen(true)}
                          href="#"
                        >
                          Change Business
                        </Link>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        {loading && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
        {products.length > 0 && (
          <div data-testid="product-list">
            <CSVLink
              variant="contained"
              data={products.map(mapProductToFlatCsvFields)}
              filename={`bring-to-market-${moment(dateForResults).format("YYYY-MM-DD")}.csv`}
              style={{ textDecoration: "none" }}
            >
              <MenuItem
                sx={{ textDecoration: "none", width: "fit-content", mb: 1 }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      textDecoration: "none",
                      // Use the theme color for the text color
                      color: "text.primary",
                    },
                  }}
                  primary="Download"
                />
              </MenuItem>
            </CSVLink>
            {products.map((product, idx) => (
              <ProductInstanceSummary
                key={idx}
                productInstance={product}
                summaryComponents={[
                  ProductQuantityChip,
                  ProductSaleFocusDisplay,
                ]}
              />
            ))}
          </div>
        )}
        {products.length === 0 && !loading && (
          <Typography textAlign="center">No products found</Typography>
        )}

        {formikRef.current?.values.marketDate && supplier && (
          <Link
            to={{ pathname: "/app/supplier/upload-inventory" }}
            state={{
              marketDate:
                formikRef.current?.values.marketDate.format("YYYY-MM-DD"),
            }}
          >
            <Button
              startIcon={<Add />}
              variant="outlined"
              sx={{ marginTop: 2 }}
            >
              Upload additional product
            </Button>
          </Link>
        )}

        <SelectBusinessDialog
          open={!supplier || supplierDialogOpen}
          onClose={() => setSupplierDialogOpen(false)}
          onSelect={(selectedBusiness) => {
            setSupplier(selectedBusiness);
            setDefaultSupplier(selectedBusiness);
          }}
          currentSelectedBusiness={supplier}
          businessAutocompleteProps={{
            suppliersOnly: true,
            onlyUsersBusinesses: true,
          }}
        />
      </Box>
    </Container>
  );
};

export default BringToMarket;
