import { ShoppingCart } from "@mui/icons-material";
import { Alert, Box, LinearProgress, Typography } from "@mui/material";
import React from "react";
import MainContentWithResponsiveDrawer from "../../../common/components/data-display/MainContentWithResponsiveDrawer";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import { useAlerts } from "../../../common/context/AlertContext";
import { useCollective } from "../../../common/context/CollectiveContext";
import { SALE_FOCUS } from "../../../common/enums/SalesFocus";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import StockLogService from "../../../common/service/StockLogService";
import { getNextOrderFulfillmentDate } from "../../../common/util/DatePickerUtilFrontend";
import { displayPrice } from "../../../common/util/DisplayUtil";
import PresaleBaseDetailsForm from "./PresaleBaseDetailsForm";
import PresaleCart from "./PresaleCart";
import PresaleInventoryProductSummary from "./PresaleInventoryProductSummary";
import PresaleInventorySearchCriteria from "./PresaleInventorySearchCriteria";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";
import ProductQuantitySelectDialog from "./ProductQuantitySelectDialog";

export default function PresaleInventory() {
  const { collectiveInfo } = useCollective();
  const { addErrorAlert } = useAlerts();
  const [selectedProductInstance, setSelectedProductInstance] =
    React.useState(null);
  const {
    presaleOrder,
    presaleOrderLoading,
    setPresaleOrder,
    timeRemainingText,
  } = usePresaleOrderContext();
  const nextMarketDate = getNextOrderFulfillmentDate(collectiveInfo);
  const [openProductQuantityDialog, setOpenProductQuantityDialog] =
    React.useState(false);
  //   const [currentOrder, setCurrentOrder] = React.useState({});
  const defaultSearchCriteria = {
    searchText: "",
    colors: [],
    dateStocked: nextMarketDate.format("YYYY-MM-DD"),
    saleFocus: SALE_FOCUS.PreSale,
    page: 1,
    itemsPerPage: 24,
  };
  const searchRef = React.useRef();

  return (
    <>
      {presaleOrderLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 500,
            width: "80%",
            marginTop: "20vh",
          }}
        >
          <Typography variant="h4" textAlign="center" gutterBottom>
            Getting the shop ready for you...
          </Typography>
          <LinearProgress variant="indeterminate" />
        </Box>
      ) : presaleOrder ? (
        <>
          <MainContentWithResponsiveDrawer
            mainContent={
              <>
                <Typography variant="h1" sx={{ ml: 1 }}>
                  Shop Market Sales
                </Typography>
                <PresaleBaseDetailsForm />
                <PaginatedSearchResults
                  ref={searchRef}
                  SearchCriteriaComponent={PresaleInventorySearchCriteria}
                  fetchSearchResults={StockLogService.getInventory}
                  ResultDisplayComponent={PresaleInventoryProductSummary}
                  SearchResultsProps={{
                    sx: {
                      display: "flex",
                      gap: 2.5,
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                    },
                  }}
                  PaginationProps={{
                    itemsPerPageOptions: [
                      { label: "24", value: 24 },
                      { label: "48", value: 48 },
                      { label: "100", value: 100 },
                    ],
                  }}
                  defaultSearchCriteria={defaultSearchCriteria}
                  onClickSearchResult={(product) => {
                    setSelectedProductInstance(product);
                    setOpenProductQuantityDialog(true);
                  }}
                  normalizeSearchCriteria={(criteria) => {
                    if (
                      criteria.dateStocked &&
                      typeof criteria.dateStocked !== "string"
                    ) {
                      criteria.dateStocked = criteria.dateStocked.toISOString();
                    }

                    return criteria;
                  }}
                />
              </>
            }
            drawerContent={
              <PresaleCart
                onClickCartItem={(product) => {
                  setSelectedProductInstance(product);
                  setOpenProductQuantityDialog(true);
                }}
                view="shopping"
              />
            }
            mobileOpenDrawerContent={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="button"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ShoppingCart sx={{ mr: 1 }} />
                  View Cart - {displayPrice(presaleOrder.total)}
                </Typography>
                <Typography variant="caption">
                  Cart expires in {timeRemainingText}
                </Typography>
              </Box>
            }
          />

          <ProductQuantitySelectDialog
            open={openProductQuantityDialog}
            onClose={() => setOpenProductQuantityDialog(false)}
            onAddProduct={(productInstance) => {
              return PresaleOrderService.addPresaleOrderItem(
                presaleOrder.id,
                productInstance
              )
                .then(setPresaleOrder)
                .then(() => {
                  searchRef.current?.rerunSearch();
                  setOpenProductQuantityDialog(false);
                })
                .catch((error) => {
                  addErrorAlert("Error adding product to cart", error);
                });
            }}
            onEditQuantity={(productToUpdate) => {
              return PresaleOrderService.updatePresaleOrderItem(
                presaleOrder.id,
                productToUpdate
              )
                .then(setPresaleOrder)
                .then(() => {
                  searchRef.current?.rerunSearch();
                  setOpenProductQuantityDialog(false);
                })
                .catch((error) => {
                  addErrorAlert("Error updating product in cart", error);
                });
            }}
            isEditing={Boolean(selectedProductInstance?.presaleOrderItemId)}
            productInstance={selectedProductInstance}
          />
        </>
      ) : (
        <Alert severity="error">Unable to load market sales</Alert>
      )}
    </>
  );
}
