import React from "react";
import { SALE_FOCUS_OPTIONS } from "../../enums/SalesFocus";
import TypographyEllipsis from "../data-display/TypographyEllipsis";

export default function ProductSaleFocusDisplay({ product }) {
  const saleFocuses = SALE_FOCUS_OPTIONS;
  const saleFocusLabel = saleFocuses.find(
    (focus) => focus.value === product.saleFocus
  )?.label;
  return (
    <TypographyEllipsis
      data-testid="product-instance-sale-focus"
      variant="body2"
      sx={{ ml: 1.5, width: "120px", textAlign: "center" }}
    >
      {saleFocusLabel}
    </TypographyEllipsis>
  );
}
