import { Container, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import * as Yup from "yup";
import ReportContainer from "../../../common/components/data-display/ReportContainer";
import { useAuthContext } from "../../../common/context/AuthContext";
import FinancialReportingService from "../../../common/service/reporting/FinancialReportingService";
import SalesByBuyerReportDisplay from "./SalesByBuyerReportDisplay";
import SalesByColorReportDisplay from "./SalesByColorReportDisplay";
import SalesByPaymentMethodReportDisplay from "./SalesByPaymentMethodReportDisplay";
import SalesByProductAndColorReportDisplay from "./SalesByProductAndColorReportDisplay";
import SalesByProductReportDisplay from "./SalesByProductReportDisplay";
import SalesBySupplierReportDisplay from "./SalesBySupplierReportDisplay";
import SalesByWeekReportDisplay from "./SalesByWeekReportDisplay";
import SalesReportCriteria from "./SalesReportCriteria";

const SalesReport = () => {
  const {
    isOwnerOfCollective,
    defaultSupplier,
    isMemberOfAnyBuyer,
    isMemberOfAnySupplier,
  } = useAuthContext();
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom data-testid="sales-report-title">
        Sales Report
      </Typography>
      <ReportContainer
        reportTitle="Sales Report"
        initialReportCriteria={{
          groupBy: isOwnerOfCollective ? "supplier" : "product",
          startDate: moment().startOf("year").format("YYYY-MM-DD"),
          endDate: moment().endOf("year").format("YYYY-MM-DD"),
          supplier: isOwnerOfCollective ? null : defaultSupplier,
          buyer: null,
        }}
        validationSchema={Yup.object().shape({
          groupBy: Yup.string().required("Group By is required"),
          startDate: Yup.string().required("Start Date is required"),
          endDate: Yup.string().required("End Date is required"),
          // If the user is only a member of a supplier, supplier is required
          // If the user is only a member of a buyer, buyer is required
          // If the user is a member of both, at least one is required
          supplier: Yup.object()
            .nullable()
            .test(
              "required-if-not-owner",
              "Supplier is required",
              function (value) {
                // If the user is an owner, supplier & buyer are not required
                if (isOwnerOfCollective) {
                  return true;
                }
                if (isMemberOfAnySupplier && !isMemberOfAnyBuyer) {
                  return Boolean(value);
                } else if (isMemberOfAnyBuyer && !isMemberOfAnySupplier) {
                  return true;
                } else if (isMemberOfAnySupplier && isMemberOfAnyBuyer) {
                  if (!value && !this.parent.buyer) {
                    return this.createError({
                      path: "supplier",
                      message: "Supplier or Buyer is required",
                    });
                  } else {
                    return true;
                  }
                }
                return true;
              }
            )
            .test(
              "must-be-blank-if-reporting-by-payment-method",
              "Supplier must be blank if reporting by Payment Method",
              function (value) {
                if (this.parent.groupBy === "paymentMethod" && value) {
                  return false;
                }
                return true;
              }
            ),
          buyer: Yup.object()
            .nullable()
            .test(
              "required-if-not-owner",
              "Buyer is required",
              function (value) {
                // If the user is an owner, supplier & buyer are not required
                if (isOwnerOfCollective) {
                  return true;
                }
                if (isMemberOfAnyBuyer && !isMemberOfAnySupplier) {
                  return Boolean(value);
                } else if (isMemberOfAnySupplier && !isMemberOfAnyBuyer) {
                  return true;
                } else if (isMemberOfAnyBuyer && isMemberOfAnySupplier) {
                  if (!value && !this.parent.supplier) {
                    return this.createError({
                      path: "buyer",
                      message: "Supplier or Buyer is required",
                    });
                  } else {
                    return true;
                  }
                }
                return true;
              }
            ),
        })}
        fetchReportData={FinancialReportingService.getSalesReport}
        ReportCriteriaComponent={SalesReportCriteria}
        ReportComponent={(reportProps) => {
          switch (reportProps.reportCriteria.groupBy) {
            case "primaryColor":
              return <SalesByColorReportDisplay {...reportProps} />;
            case "paymentMethod":
              return <SalesByPaymentMethodReportDisplay {...reportProps} />;
            case "product":
              return <SalesByProductReportDisplay {...reportProps} />;
            case "productAndColor":
              return <SalesByProductAndColorReportDisplay {...reportProps} />;
            case "supplier":
              return <SalesBySupplierReportDisplay {...reportProps} />;
            case "buyer":
              return <SalesByBuyerReportDisplay {...reportProps} />;
            case "week":
              return <SalesByWeekReportDisplay {...reportProps} />;
            default:
              return null;
          }
        }}
      />
    </Container>
  );
};

export default SalesReport;
