import { NotInterested, ReceiptLong, Warning } from "@mui/icons-material";
import { Box, ListItemButton, Tooltip, Typography } from "@mui/material";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import TypographyEllipsis from "../../common/components/data-display/TypographyEllipsis";
import {
  displayAdvancedRequestName,
  displayPrice,
} from "../../common/util/DisplayUtil";

export function AdminAdvancedRequestSummary({ ...props }) {
  return <AdvancedRequestSummary {...props} adminView />;
}

export default function AdvancedRequestSummary({
  advancedRequest,
  item,
  adminView = false,
  ...props
}) {
  const request = advancedRequest || item;
  let linkProps = {};
  if (!props.onClick) {
    linkProps = {
      LinkComponent: Link,
      to: adminView
        ? `/app/business-admin/manage-request/${request.id}`
        : `/app/buyer/product-requests/${request.id}`,
    };
  }
  return (
    <ListItemButton
      sx={{
        mb: 0.25,
        p: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
      }}
      data-testid={"advanced-request-summary"}
      {...linkProps}
      aria-label={`View request ${request.orderReference ?? ` for ${moment(request.pickupDate).format("M/D/YY")}`}`}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          width: "100%",
          mr: 0,
          flexWrap: "wrap",
          gap: 1,
          alignItems: "center",
        }}
      >
        {adminView && (
          <Box sx={{}}>
            {Boolean(request.isLinkedToInvoice) && (
              <Tooltip title="Linked to an invoice">
                <ReceiptLong color="info" />
              </Tooltip>
            )}
            {Boolean(!request.isLinkedToInvoice) && (
              <Tooltip title="Not linked to an invoice">
                <NotInterested />
              </Tooltip>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Request Date</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 800, width: 120, display: "inline-block" }}
            data-testid="request-pickup-date"
          >
            {/* display date with day of week, day month year */}
            {moment(request.pickupDate).format("ddd, M/D/YY")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Order #</Typography>
          <TypographyEllipsis
            sx={{
              textOverflow: "ellipsis",
              overflowX: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
              width: 80,
            }}
            data-testid="request-order-reference"
          >
            {displayAdvancedRequestName(request)}
          </TypographyEllipsis>
        </Box>
        {adminView && (
          <Box sx={{ display: "inline-block" }}>
            <BusinessDisplay
              label="Buyer"
              business={request.buyerBusiness}
              sx={{ width: 180 }}
            />
          </Box>
        )}
        {request.pickupLocation?.name && (
          <Box sx={{ display: "flex", flexDirection: "column", width: 130 }}>
            <TypographyEllipsis variant="caption">
              Pickup Option
            </TypographyEllipsis>
            <TypographyEllipsis
              variant="body1"
              sx={{ display: "inline-block" }}
              data-testid="order-sale-date"
            >
              {request.pickupLocation.name}
            </TypographyEllipsis>
          </Box>
        )}

        {request.fulfillmentStatus === "DRAFT" ? (
          <Box sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Warning color="warning" sx={{ marginRight: 1.5 }} />
              <Tooltip title="This request is a draft and has not been submitted">
                <Typography variant="body1" color="warning">
                  Draft
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          ""
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
            Est. Total
          </Typography>
          <Typography
            variant="body1"
            sx={{
              alignSelf: "flex-end",
              fontWeight: 800,
              width: 95,
              display: "inline-block",
              textAlign: "end",
              marginLeft: "auto",
            }}
            data-testid="request-estimated-total"
          >
            {displayPrice(request.estimatedTotal)}
          </Typography>
        </Box>
      </Box>
    </ListItemButton>
  );
}
