import React, { createContext, useCallback, useContext, useState } from "react";
import { useAppTheme } from "../../AppTheme";
import ApplicationLoading from "../components/ApplicationLoading";
import CollectiveService from "../service/CollectiveService";
import { useAlerts } from "./AlertContext";

const CollectiveContext = createContext();

export const COLLECTIVE_PROPERTY = {
  PRIMARY_COLOR: "primaryColor",
  SECONDARY_COLOR: "secondaryColor",
  HOW_TO_PAY_INSTRUCTIONS: "howToPayInstructions",
  REPLY_TO_EMAIL: "replyToEmail",
  CONTACT_PHONE: "contactPhone",
  ADVANCED_REQUEST_MAX_DAYS: "advancedRequestMaxDays",
  MARKET_CLOSED_MESSAGE: "marketClosedMessage",
  PRODUCT_REQUEST_REVIEW_INFO: "productRequestReviewInfo",
  IS_MARKET_CLOSED: "isMarketClosed",
  SUPPLIER_DROPOFF_DAYS_OF_WEEK: "supplierDropoffDaysOfWeek",
  ORDER_FULFILLMENT_DAYS_OF_WEEK: "orderFulfillmentDaysOfWeek",
  UPLOAD_CUTOFF_DAYS_IN_ADVANCE: "uploadCutoffDaysInAdvance",
  UPLOAD_CUTOFF_TIME: "uploadCutoffTime",
  PRESALE_OPEN_DAYS_IN_ADVANCE: "presaleOpenDaysInAdvance",
  PRESALE_OPEN_TIME: "presaleOpenTime",
  PRESALE_CLOSE_DAYS_IN_ADVANCE: "presaleCloseDaysInAdvance",
  PRESALE_CLOSE_TIME: "presaleCloseTime",
  CART_TIMEOUT_MINUTES: "cartTimeoutMinutes",
};

export const CollectiveProvider = ({ children }) => {
  const { addErrorAlert } = useAlerts();
  const [collectiveInfo, setCollectiveInfo] = useState(null);
  const { setPrimaryColor, setSecondaryColor } = useAppTheme();

  const reloadCollective = useCallback(() => {
    CollectiveService.getCurrentCollectiveInfo()
      .then((info) => {
        setCollectiveInfo(info);
        setPrimaryColor(info.primaryColor);
        setSecondaryColor(info.secondaryColor);
      })
      .catch((e) => addErrorAlert("Failed to load collective info", e));
  }, [addErrorAlert, setPrimaryColor, setSecondaryColor]);

  React.useEffect(() => {
    reloadCollective();
  }, [reloadCollective]);

  function getCollectiveProperty(propertyName, defaultValue) {
    // If no property is found with the given name, return the default value
    const collectiveProperties = collectiveInfo?.properties || {};
    const propVal = collectiveProperties[propertyName];
    if (propVal === undefined || propVal === null) {
      return defaultValue;
    }
    return propVal;
  }

  return (
    <CollectiveContext.Provider
      value={{ collectiveInfo, reloadCollective, getCollectiveProperty }}
    >
      {collectiveInfo ? children : <ApplicationLoading />}
    </CollectiveContext.Provider>
  );
};

export const useCollective = () => useContext(CollectiveContext);
