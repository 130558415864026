/* eslint-disable no-console */
import axios from "axios";
import { AuthenticationService } from "../common/service/AuthenticationService";
import ErrorLogService from "../common/service/ErrorLogService";

export function setupAxiosDefaults() {
  axios.defaults.withCredentials = true;

  // Add response interceptor to handle 401 responses
  axios.interceptors.response.use(
    // Default to returning the data from any successful response. Cleans up services nicely
    undefined,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          // Attempt to refresh the authentication token
          await AuthenticationService.refreshToken();
        } catch (refreshError) {
          // Handle refresh token failure or other errors - just log out
          console.error(refreshError);
          if (window.globalLogout) {
            window.globalLogout();
          }
          try {
            ErrorLogService.logError(
              refreshError,
              "Error refreshing authentication token"
            );
          } catch (logError) {
            console.error("Error logging error 😢", logError);
          }
          return Promise.reject(refreshError);
        }
        try {
          return axios.request(originalRequest);
        } catch (error) {
          // Handle any other errors
          return Promise.reject(error);
        }
      }

      // Return any other errors without modification
      return Promise.reject(error);
    }
  );
}
