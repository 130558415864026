import { Search } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import FormSelect from "../../../common/components/form/FormSelect";
import { useAuthContext } from "../../../common/context/AuthContext";

export default function AgedOutReportCriteria({ onSubmitReport }) {
  const { isManagerOfCollective } = useAuthContext();
  const groupByOptions = [
    { value: "product", label: "Product" },
    { value: "primaryColor", label: "Color" },
    { value: "week", label: "Week" },
  ];

  if (isManagerOfCollective) {
    groupByOptions.push({ value: "supplier", label: "Supplier" });
  }

  return (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        <Field
          component={FormSelect}
          name="groupBy"
          label="Group By"
          options={groupByOptions}
          sx={{ width: 140 }}
        />
        <Field
          component={FormDatePicker}
          name="startDate"
          label="Start Date"
          size="small"
          sx={{ width: 170 }}
        />
        <Field
          component={FormDatePicker}
          name="endDate"
          label="End Date"
          size="small"
          sx={{ width: 170 }}
        />
        <Field
          component={BusinessAutocomplete}
          name="supplier"
          label="Supplier"
          sx={{ width: 320 }}
          suppliersOnly
          onlyUsersBusinesses={!isManagerOfCollective}
          businessesUserManages={!isManagerOfCollective}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<Search />}
        sx={{ marginRight: "auto" }}
        onClick={onSubmitReport}
      >
        Run Report
      </Button>
    </Box>
  );
}
