import { Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

export default function ProductMarketDateDisplay({ product }) {
  return (
    <Typography
      data-testid="product-instance-pickup-date"
      variant="body2"
      sx={{ ml: 1.5, width: 120, textAlign: "end" }}
    >
      {moment(product.pickupDate).format("L")}
    </Typography>
  );
}
