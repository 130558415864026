import { Skeleton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import LoadingOverlay from "../../common/components/data-display/LoadingOverlay";
import FlowerQualitySelect from "../../common/components/form/FlowerQualitySelect";
import FormFlowerColorPicker from "../../common/components/form/FormFlowerColorPicker";
import { FormQuantityField } from "../../common/components/form/FormQuantityField";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import BaseProductService from "../../common/service/BaseProductService";
import { displayPrice } from "../../common/util/DisplayUtil";
import ProductPrice from "./ProductPrice";

function getDefaultValues(baseProduct, productInstance) {
  if (baseProduct) {
    return {
      attributes: {
        primaryColor: "",
        secondaryColor: "",
        quality: "Standard",
      },
      name: baseProduct.name,
      quantityRequested: 1,
      baseProductId: baseProduct.id,
      estimatedUnitPrice: baseProduct.minPrice || baseProduct.maxPrice,
    };
  } else if (productInstance) {
    return {
      attributes: {
        primaryColor:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "PrimaryColor"
          )?.attributeValue || "",
        secondaryColor:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "SecondaryColor"
          )?.attributeValue || "",

        quality:
          productInstance.attributes.find(
            (attr) => attr.attributeName === "Quality"
          )?.attributeValue || "Standard",
      },
      name: productInstance.name,
      quantityRequested: productInstance.quantityRequested || "",
      baseProductId: productInstance.baseProductId,
      estimatedUnitPrice: productInstance.estimatedUnitPrice,
    };
  }
}

export default function AdvancedProductDialog({
  advancedRequest,
  isOpen,
  onClose,
  baseProduct,
  productInstance,
  onAddProduct,
  onDeleteProduct,
}) {
  const formikRef = React.useRef(null);
  const [baseProductDetails, setBaseProductDetails] = React.useState();
  const { addErrorAlert } = useAlerts();

  React.useEffect(() => {
    if ((productInstance || baseProduct) && formikRef.current) {
      formikRef.current.resetForm(
        getDefaultValues(baseProduct, productInstance)
      );
    }

    if (baseProduct || productInstance) {
      BaseProductService.getBaseProductById(
        baseProduct?.id || productInstance?.baseProductId,
        true
      )
        .then((baseProductDetails) => {
          setBaseProductDetails(baseProductDetails);
        })
        .catch((err) => {
          addErrorAlert("Error getting base product details", err);
        });
    }
  }, [baseProduct, productInstance, addErrorAlert]);

  const handleDelete = async () => {
    formikRef.current.setSubmitting(true);
    try {
      const updatedReq = await AdvancedRequestService.deleteProductFromRequest(
        advancedRequest.id,
        productInstance.id
      );
      onDeleteProduct(updatedReq);
    } catch (err) {
      addErrorAlert("Error deleting product", err);
    }
    formikRef.current.setSubmitting(false);
  };

  const handleSubmit = async (values) => {
    if (productInstance) {
      const productToUpdate = {
        quantityRequested: values.quantityRequested,
        id: productInstance.id,
      };
      try {
        const reqWithProduct =
          await AdvancedRequestService.updateProductInRequest(
            advancedRequest.id,
            productToUpdate
          );
        onAddProduct(reqWithProduct);
      } catch (err) {
        addErrorAlert("Error updating product", err);
      }
    } else {
      const productToSubmit = {
        productInstance: {
          name: values.name,
          baseProductId: values.baseProductId,
          instanceAttributes: [
            {
              attributeName: "PrimaryColor",
              attributeValue: values.attributes.primaryColor,
            },
            {
              attributeName: "SecondaryColor",
              attributeValue: values.attributes.secondaryColor,
            },
            {
              attributeName: "Quality",
              attributeValue: values.attributes.quality,
            },
          ],
        },
        quantityRequested: values.quantityRequested,
        estimatedUnitPrice: values.estimatedUnitPrice,
      };
      try {
        const reqWithProduct = await AdvancedRequestService.addProductToRequest(
          advancedRequest.id,
          productToSubmit
        );
        onAddProduct(reqWithProduct);
      } catch (err) {
        addErrorAlert("Error adding product", err);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Formik
        initialValues={getDefaultValues(baseProduct, productInstance)}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={
          // Yup validation. Require positive quantity and a primary color
          Yup.object().shape({
            quantityRequested: Yup.number()
              .typeError("Must be a number")
              .required("Quantity is required")
              .positive("Enter a valid quantity"),
            attributes: Yup.object().shape({
              primaryColor: Yup.string().required("Primary color is required"),
            }),
            estimatedUnitPrice: Yup.number().required(
              "Error determining price; please try reloading the page."
            ),
          })
        }
      >
        {({ values, isSubmitting }) => (
          <Form autoComplete="off">
            <DialogTitle>
              {baseProduct?.name || productInstance?.name}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ pt: 1, maxWidth: 250 }}>
                {baseProductDetails ? (
                  <>
                    <Grid xs={12}>
                      <Field
                        component={FormFlowerColorPicker}
                        name="attributes.primaryColor"
                        label="Primary Color"
                        disabled={!!productInstance}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        component={FormFlowerColorPicker}
                        name="attributes.secondaryColor"
                        label="Secondary Color"
                        disabled={!!productInstance}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <FlowerQualitySelect
                        name="attributes.quality"
                        disabled={!!productInstance}
                        productDetails={baseProductDetails}
                        includeBasicQuality={false}
                      />
                    </Grid>
                    <Grid xsOffset={6} xs={6}>
                      <Field
                        component={FormQuantityField}
                        name="quantityRequested"
                        label="Quantity"
                      />
                    </Grid>
                    <Grid xs={12} textAlign="end">
                      <ProductPrice
                        name="estimatedUnitPrice"
                        baseProductId={
                          baseProduct?.id || productInstance?.baseProductId
                        }
                        priceGroups={baseProductDetails.priceGroups}
                        defaultPrice={baseProductDetails.defaultPrice}
                        fixedPrice={!!productInstance}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                )}
                <Grid xs={12} textAlign="end">
                  <Typography>
                    {values.estimatedUnitPrice && values.quantityRequested && (
                      <strong>
                        Total:{" "}
                        {displayPrice(
                          values.estimatedUnitPrice * values.quantityRequested
                        )}
                      </strong>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "space-between", position: "relative" }}
            >
              <Button onClick={onClose}>Cancel</Button>
              {productInstance && !productInstance.quantityFulfilled && (
                <Button color="error" onClick={handleDelete} variant="outlined">
                  Remove
                </Button>
              )}
              <Button color="primary" type="submit" variant="outlined">
                {!!productInstance ? `Update` : `Add`}
              </Button>
            </DialogActions>
            {isSubmitting && <LoadingOverlay />}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
