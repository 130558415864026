import { Add, Download } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import { useCollective } from "../../../common/context/CollectiveContext.jsx";
import BusinessService from "../../../common/service/BusinessService.js";
import BusinessSearchCriteria from "./BusinessSearchCriteria.jsx";
import BusinessSummary from "./BusinessSummary.jsx";

const ManageBusinesses = () => {
  const { collectiveInfo } = useCollective();
  const { addErrorAlert } = useAlerts();

  const defaultSearchCriteria = {
    searchText: "",
    isBuyer: false,
    isSupplier: false,
    isPlatinum: false,
    activeStatus: "",
    page: 1,
    itemsPerPage: 10,
    ignoreCollectiveFilter: Boolean(collectiveInfo.isAdminCollective),
  };
  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Manage Businesses
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/app/business-admin/manage-businesses/new">
            <Button variant="contained" color="primary" startIcon={<Add />}>
              Add Business
            </Button>
          </Link>
        </Grid>
      </Grid>
      <PaginatedSearchResults
        fetchSearchResults={BusinessService.getBusinesses}
        ResultDisplayComponent={BusinessSummary}
        SearchCriteriaComponent={BusinessSearchCriteria}
        defaultSearchCriteria={defaultSearchCriteria}
        DownloadResultsComponent={({ searchCriteria, searchResults }) => (
          <Box textAlign={"end"}>
            <Button
              startIcon={<Download />}
              variant="outlined"
              disabled={!(searchResults?.length > 0)}
              onClick={async () => {
                try {
                  await BusinessService.downloadBusinesses(searchCriteria);
                } catch (e) {
                  addErrorAlert("Failed to download businesses", e);
                }
              }}
            >
              Download
            </Button>
          </Box>
        )}
      />
    </Container>
  );
};

export default ManageBusinesses;
