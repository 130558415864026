export const SALE_FOCUS = {
  PreSale: "Pre-Sale",
  Speculation: "Speculation",
  AdvancedOrder: "Advanced Order",
};

export const SALE_FOCUS_OPTIONS = [
  { value: SALE_FOCUS.PreSale, label: "Market Sales" },
  { value: SALE_FOCUS.Speculation, label: "Speculation" },
  { value: SALE_FOCUS.AdvancedOrder, label: "Product Request" },
];

export const SALE_FOCUS_OPTIONS_NO_ADVANCED_ORDER = [
  { value: SALE_FOCUS.PreSale, label: "Market Sales" },
  { value: SALE_FOCUS.Speculation, label: "Speculation" },
];
