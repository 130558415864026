import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import NewPasswordRequirements, {
  PasswordRequirementSchema,
} from "../../../pages/Auth/NewPasswordRequirements";
import { useAlerts } from "../../context/AlertContext";
import { AuthenticationService } from "../../service/AuthenticationService";
import { FormTextField } from "../form/FormTextField";

export default function PasswordChangeDialog({ open, onClose, allowCancel }) {
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  async function handleSubmit(values) {
    return AuthenticationService.changePassword(values.password)
      .then((updatedUserInfo) => {
        onClose(updatedUserInfo);
        addSuccessAlert("Password changed");
      })
      .catch((err) => {
        addErrorAlert("Error changing password", err);
      });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {open && (
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            password: PasswordRequirementSchema,
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
          })}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ values }) => (
            <Form autoComplete="off">
              <DialogTitle>Set New Password</DialogTitle>
              <DialogContent>
                <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                  <Field
                    name="password"
                    label="New Password"
                    component={FormTextField}
                    type="password"
                  />
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    component={FormTextField}
                    type="password"
                  />
                  <NewPasswordRequirements passwordToTest={values.password} />
                </Box>
              </DialogContent>
              <DialogActions>
                {allowCancel && (
                  <Button onClick={onClose} type="button">
                    Cancel
                  </Button>
                )}
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
