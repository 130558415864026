import { Box, ListItemButton } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { Link } from "react-router-dom";
import TypographyEllipsis from "../../../common/components/data-display/TypographyEllipsis";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { useCollective } from "../../../common/context/CollectiveContext";

export default function TermsConditionsSummary({
  sx,
  item,
  onClick,
  ...props
}) {
  const { id, type, version, createdAt, createdBy } = item;
  const { collectiveInfo } = useCollective();
  let linkProps = {};
  if (!props.onClick) {
    linkProps = {
      LinkComponent: Link,
      to: `/app/business-admin/manage-terms-conditions/view/${id}`,
    };
  }

  const timezoneFromServer = process.env.SERVER_TIMEZONE || "UTC";
  const momentConstructor = timezoneFromServer === "UTC" ? moment.utc : moment;

  return (
    <ListItemButton
      sx={{
        mb: 0.125,
        width: "100%",
        maxWidth: 600,
        textAlign: "start",
        ...sx,
      }}
      {...linkProps}
      data-testid={"terms-conditions-summary"}
      {...props}
    >
      {/* If onClick prop is provided, use a CardActionArea - else use a Box */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          p: 0.5,
          flexWrap: "nowrap",
        }}
        onClick={onClick}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Box
            sx={{
              ml: 1.5,
              flexGrow: 1,
              maxWidth: 220,
              textWrap: "wrap",
            }}
          >
            <TypographyEllipsis
              variant="body1"
              sx={{
                fontWeight: 800,
              }}
            >
              {type === "buyer" ? "Buyer" : "Supplier"} T&C - v{version}
            </TypographyEllipsis>
            <TypographyEllipsis variant="body2">
              {momentConstructor(createdAt)
                .tz(collectiveInfo.timezone)
                .format("LLL")}
            </TypographyEllipsis>
          </Box>
        </Box>
        <UserDisplay user={createdBy} label="Edited By" />
      </Box>
    </ListItemButton>
  );
}
