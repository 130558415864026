export const FLOWER_QUALITY = {
  Basic: "Basic",
  Standard: "Standard",
  Premium: "Premium",
};

export const FLOWER_QUALITY_OPTIONS = [
  FLOWER_QUALITY.Basic,
  FLOWER_QUALITY.Standard,
  FLOWER_QUALITY.Premium,
];

export const FLOWER_QUALITY_OPTIONS_NO_BASIC = [
  FLOWER_QUALITY.Standard,
  FLOWER_QUALITY.Premium,
];
