/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import PasswordChangeDialog from "../components/dialogs/PasswordChangeDialog";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AuthenticationService } from "../service/AuthenticationService";
import { MasqueradeAuthService } from "../service/MasqueradeAuthService";
import { PermissionService } from "../service/PermissionService";
import UserService from "../service/UserService";
import { useCollective } from "./CollectiveContext";

export const CURRENT_USER_STORAGE_KEY = "APP_CURRENT_USER";
export const AuthContext = React.createContext();

export default function AuthContextProvider({ children }) {
  const { collectiveInfo } = useCollective();
  const [needsLogout, setNeedsLogout] = React.useState(false);
  const [defaultSupplier, _setDefaultSupplier] = React.useState(null);
  const [defaultBuyer, _setDefaultBuyer] = React.useState(null);
  const [showPasswordChangeDialog, setShowPasswordChangeDialog] =
    React.useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useLocalStorage(
    CURRENT_USER_STORAGE_KEY,
    null
  );

  React.useEffect(() => {
    if (needsLogout) {
      setCurrentUser(null);
      navigate("/login");
      AuthenticationService.logout();
      setNeedsLogout(false);
    }
  }, [navigate, needsLogout, setCurrentUser, setNeedsLogout]);

  async function masqueradeAsUser(userId) {
    return MasqueradeAuthService.masqueradeAsUser(userId)
      .then(logInAsUser)
      .catch((e) => {
        alert("error logging in as user");
        console.error(e);
      });
  }

  async function masqueradeLogout() {
    return MasqueradeAuthService.masqueradeLogout()
      .then(logInAsUser)
      .catch((e) => {
        alert("error logging out as user");
        console.error(e);
      });
  }

  function setDefaultBusinesses(user) {
    const businesses = user.businessRoles;
    if (!businesses?.length) {
      return;
    }

    const suppliers = businesses.filter(
      (bizRole) => bizRole.supplierStatus === "yes"
    );
    if (suppliers.length === 1) {
      _setDefaultSupplier({ ...suppliers[0], id: suppliers[0].businessId });
    } else {
      const defSupplierBusiness = suppliers.find(
        (supplier) => supplier.id === user.defaultSupplierBusinessId
      );
      _setDefaultSupplier(defSupplierBusiness);
    }
    const buyers = businesses.filter(
      (bizRole) => bizRole.buyerStatus === "yes"
    );
    if (buyers.length === 1) {
      _setDefaultBuyer({ ...buyers[0], id: buyers[0].businessId });
    } else {
      const defBuyerBusiness = buyers.find(
        (buyer) => buyer.id === user.defaultBuyerBusinessId
      );
      _setDefaultBuyer(defBuyerBusiness);
    }
  }

  async function setDefaultBuyer(buyer) {
    const updatedUser = await UserService.setDefaultBusiness(buyer.id, "buyer");

    setCurrentUser({ ...updatedUser });
    setDefaultBusinesses(updatedUser);
  }

  async function setDefaultSupplier(supplier) {
    const updatedUser = await UserService.setDefaultBusiness(
      supplier.id,
      "supplier"
    );
    setCurrentUser({ ...updatedUser });
    setDefaultBusinesses(updatedUser);
  }

  function logInAsUser(user) {
    setCurrentUser({ ...user });
    setDefaultBusinesses(user);
    navigate("/app");
  }

  async function logOut() {
    setNeedsLogout(true);
  }

  window.globalLogout = logOut;

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(currentUser),
        isMasquerading: Boolean(currentUser?.masqueradeUser),
        currentUser,
        masqueradeAsUser,
        logInAsUser,
        masqueradeLogout,
        logOut,
        isMemberOfAnyBuyer: PermissionService.isMemberOfAnyBuyer(currentUser),
        isManagerOfAnyBuyer: PermissionService.isManagerOfAnyBuyer(currentUser),
        isMemberOfAnySupplier:
          PermissionService.isMemberOfAnySupplier(currentUser),
        isManagerOfAnySupplier:
          PermissionService.isManagerOfAnySupplier(currentUser),
        isOwnerOfCollective: PermissionService.isOwnerOfCollective(
          collectiveInfo,
          currentUser
        ),
        isManagerOfCollective: PermissionService.isManagerOfCollective(
          collectiveInfo,
          currentUser
        ),
        isMemberOfCollective: PermissionService.isMemberOfCollective(
          collectiveInfo,
          currentUser
        ),
        isOwnerOfBusiness: (businessId) => {
          return PermissionService.isOwnerOfBusiness(businessId, currentUser);
        },
        isManagerOfBusiness: (businessId) => {
          return PermissionService.isManagerOfBusiness(businessId, currentUser);
        },
        isMemberOfBusiness: (businessId) => {
          return PermissionService.isMemberOfBusiness(businessId, currentUser);
        },
        supplierBusinesses: currentUser?.businessRoles.filter(
          (bizRole) => bizRole.supplierStatus === "yes"
        ),
        buyerBusinesses: currentUser?.businessRoles.filter(
          (bizRole) => bizRole.buyerStatus === "yes"
        ),
        isMemberOfPlatinumBuyer:
          PermissionService.isMemberOfPlatinumBuyer(currentUser),
        defaultBuyer,
        setDefaultBuyer,
        defaultSupplier,
        setDefaultSupplier,
        openPasswordChangeDialog: () => setShowPasswordChangeDialog(true),
      }}
    >
      {children}
      <PasswordChangeDialog
        open={Boolean(
          showPasswordChangeDialog || currentUser?.needsPasswordSet
        )}
        onClose={(userIfPwChanged) => {
          if (userIfPwChanged?.email !== undefined) {
            setCurrentUser(userIfPwChanged);
          }
          setShowPasswordChangeDialog(false);
        }}
        allowCancel={!currentUser?.needsPasswordSet}
      />
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return React.useContext(AuthContext);
}
