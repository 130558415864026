export const PermissionService = {
  isOwnerOfCollective: (collective, userWithRoles) => {
    if (!collective || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === collective.businessId && bizRole.role === "OWNER"
    );
  },
  isManagerOfCollective: (collective, userWithRoles) => {
    if (!collective || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === collective.businessId &&
        (bizRole.role === "MANAGER" || bizRole.role === "OWNER")
    );
  },
  isMemberOfCollective: (collective, userWithRoles) => {
    if (!collective || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === collective.businessId
    );
  },
  isBusinessSupplier: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === businessId && bizRole.supplierStatus === "yes"
    );
  },
  isBusinessBuyer: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === businessId && bizRole.buyerStatus === "yes"
    );
  },
  isOwnerOfBusiness: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === businessId && bizRole.role === "OWNER"
    );
  },
  isManagerOfBusiness: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.businessId === businessId &&
        (bizRole.role === "MANAGER" || bizRole.role === "OWNER")
    );
  },
  isMemberOfBusiness: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === businessId
    );
  },
  isMemberOfAnyBuyer: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.buyerStatus === "yes"
    );
  },
  isManagerOfAnyBuyer: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.buyerStatus === "yes" &&
        (bizRole.role === "MANAGER" || bizRole.role === "OWNER")
    );
  },
  isMemberOfAnySupplier: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.supplierStatus === "yes"
    );
  },
  isManagerOfAnySupplier: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) =>
        bizRole.supplierStatus === "yes" &&
        (bizRole.role === "MANAGER" || bizRole.role === "OWNER")
    );
  },
  isMemberOfPlatinumBuyer: (userWithRoles) => {
    if (!userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.buyerStatus === "yes" && bizRole.platinumMember
    );
  },
  isBusinessPlatinumMember: (businessId, userWithRoles) => {
    if (!businessId || !userWithRoles) return false;
    return userWithRoles.businessRoles.some(
      (bizRole) => bizRole.businessId === businessId && bizRole.platinumMember
    );
  },
  isAdminCollective: (collective) => {
    if (!collective) return false;
    return collective.isAdminCollective;
  },
};
