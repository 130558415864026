import { Box, ListItem } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import TypographyEllipsis from "../../../common/components/data-display/TypographyEllipsis";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { useCollective } from "../../../common/context/CollectiveContext";

export default function TermsConditionsLogSummary({ sx, item, ...props }) {
  const { createdAt, createdBy } = item;
  const { collectiveInfo } = useCollective();
  const timezoneFromServer = process.env.SERVER_TIMEZONE || "UTC";
  const momentConstructor = timezoneFromServer === "UTC" ? moment.utc : moment;
  return (
    <ListItem
      sx={{
        mb: 0.125,
        width: "100%",
        maxWidth: 600,
        textAlign: "start",
        ...sx,
      }}
      data-testid={"terms-conditions-log-summary"}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          p: 0.5,
          flexWrap: "nowrap",
        }}
      >
        <UserDisplay user={createdBy} label="User" />
        <TypographyEllipsis variant="body2">
          Agreed on{" "}
          {momentConstructor(createdAt)
            .tz(collectiveInfo.timezone)
            .format("LLL")}
        </TypographyEllipsis>
      </Box>
    </ListItem>
  );
}
