import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { Bar } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { displayPercentage } from "../../../common/util/DisplayUtil";

export default function AgedOutBySupplierReportDisplay({
  reportData,
  reportCriteria,
  reportTitle,
}) {
  const { startDate, endDate } = reportCriteria;

  const labels = reportData.map((data) => data.supplierName);

  const reportOptions = {
    plugins: {
      title: {
        display: true,
        text:
          reportTitle +
          ": " +
          moment(startDate).format("MM/DD/YYYY") +
          " - " +
          moment(endDate).format("MM/DD/YYYY"),
      },
    },
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Stocked Quantity",
        data: reportData.map((data) => data.stockedQuantity),
        backgroundColor: "rgba(99, 148, 255, 0.2)",
        borderColor: "rgb(56, 72, 211)",
        borderWidth: 1,
      },
      {
        label: "Aged Out Quantity",
        data: reportData.map((data) => data.agedOutQuantity),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
  const agedOutTotal = reportData.reduce(
    (acc, data) => data.agedOutQuantity + acc,
    0
  );
  const stockedTotal = reportData.reduce(
    (acc, data) => data.stockedQuantity + acc,
    0
  );
  const percentAgedOutTotal = (agedOutTotal || 0) / (stockedTotal || 1);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ height: `${labels.length * 42 + 50}px`, width: "100%" }}>
        <Bar options={reportOptions} data={data} />
      </Box>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Report Data</Typography>
        <CSVLink
          variant="contained"
          data={reportData}
          filename={`aged-out-report-by-supplier-${moment(startDate).format("MM-DD-YYYY")}-${moment(endDate).format("MM-DD-YYYY")}.csv`}
        >
          <Button startIcon={<Download />}>Download Report</Button>
        </CSVLink>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "800" }}>Supplier</TableCell>
              <TableCell sx={{ fontWeight: "800" }}># Stocked</TableCell>
              <TableCell sx={{ fontWeight: "800" }}># Aged Out</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>% Aged Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((data, i) => (
              <TableRow key={i}>
                <TableCell>{data.supplierName}</TableCell>
                <TableCell>{data.stockedQuantity}</TableCell>
                <TableCell>{data.agedOutQuantity}</TableCell>
                <TableCell>
                  {displayPercentage(data.agedOutPercentage, 1)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell>
                <b>
                  {reportData.reduce(
                    (acc, data) => acc + data.stockedQuantity,
                    0
                  )}
                </b>
              </TableCell>
              <TableCell>
                <b>
                  {reportData.reduce(
                    (acc, data) => acc + data.agedOutQuantity,
                    0
                  )}
                </b>
              </TableCell>
              <TableCell>
                <b>{displayPercentage(percentAgedOutTotal, 1)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
