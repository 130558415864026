import { Add } from "@mui/icons-material";
import { Box, Button, List } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import AddUserToBusinessDialog from "../../../common/components/form/AddUserToBusinessDialog";
import AutoSubmitOnChange from "../../../common/components/form/AutoSubmitOnChange";
import UserAutocomplete from "../../../common/components/form/UserAutocomplete";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import BusinessService from "../../../common/service/BusinessService";
import BusinessUserSummary from "./BusinessUserSummary";

export default function EditBusinessUsers({
  businessId,
  businessUsers,
  setBusinessUsers,
}) {
  const { isMemberOfCollective, isOwnerOfBusiness } = useAuthContext();
  const ownsBusiness = isOwnerOfBusiness(parseInt(businessId));
  const [newUserDialogOpen, setNewUserDialogOpen] = React.useState(false);
  const [newUserDialogText, setNewUserDialogText] = React.useState("");
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  async function addBusinessUser(values) {
    try {
      const addedUser = await BusinessService.createBusinessUser({
        userId: values.user.id,
        businessId,
        role: "ASSOCIATE",
      });
      setBusinessUsers([...businessUsers, addedUser]);
      formikRef.current.resetForm({
        values: { user: null, businessId, role: "ASSOCIATE" },
      });
      addSuccessAlert("User added");
    } catch (error) {
      addErrorAlert("Error adding user to business", error);
    }
  }

  async function deleteBusinessUser(businessUser) {
    try {
      await BusinessService.deleteBusinessUser(businessId, businessUser.userId);
      const updatedUsers = businessUsers.filter(
        (bu) => bu.userId !== businessUser.userId
      );
      setBusinessUsers(updatedUsers);
      addSuccessAlert("User removed");
    } catch (error) {
      addErrorAlert("Error removing user from business", error);
    }
  }

  async function editBusinessUser(businessUser) {
    return await BusinessService.updateBusinessUser({
      businessId,
      role: businessUser.role,
      userId: businessUser.userId,
    });
  }

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {isMemberOfCollective && (
        <Grid xs={12}>
          <Formik
            initialValues={{ businessId, user: null }}
            validationSchema={Yup.object().shape({
              user: Yup.mixed().required("Required"),
            })}
            onSubmit={addBusinessUser}
            innerRef={formikRef}
          >
            {() => (
              <Form autoComplete="off">
                <AutoSubmitOnChange />
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} md={4}>
                    <Field
                      component={UserAutocomplete}
                      label="Add user..."
                      name="user"
                      onAddNewUser={(text) => {
                        setNewUserDialogText(text);
                        setNewUserDialogOpen(true);
                      }}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
      {ownsBusiness && !isMemberOfCollective && (
        <Grid xs={12}>
          <Button
            onClick={() => setNewUserDialogOpen(true)}
            variant="contained"
            startIcon={<Add />}
          >
            Add User
          </Button>
        </Grid>
      )}
      {businessUsers?.length === 0 && (
        <Grid xs={12}>
          <Box sx={{ p: 2 }}>No users found for this business.</Box>
        </Grid>
      )}
      {businessUsers?.length > 0 && (
        <Grid xs={12}>
          <List sx={{ width: "100%" }} dense>
            {businessUsers.map((businessUser, i) => (
              <BusinessUserSummary
                businessUser={businessUser}
                showBusinessInfo={false}
                showUserInfo={true}
                editBusinessUser={editBusinessUser}
                deleteBusinessUser={deleteBusinessUser}
                key={businessUser.userId}
              />
            ))}
          </List>
        </Grid>
      )}
      <AddUserToBusinessDialog
        businessId={businessId}
        open={newUserDialogOpen}
        initialText={newUserDialogText}
        onCancel={() => setNewUserDialogOpen(false)}
        onAddUser={(addedUser) => {
          if (formikRef.current) {
            formikRef.current.resetForm();
          }
          setNewUserDialogOpen(false);
          setBusinessUsers([...businessUsers, addedUser]);
        }}
      />
    </Grid>
  );
}
