import React from "react";

import { Check } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormQuantityField } from "../../../common/components/form/FormQuantityField";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import AdvancedRequestService from "../../../common/service/AdvancedRequestService";
import { ProductInstanceSummarySupplierView } from "./AdvancedRequestSupplierView";

const FulfillProductDialog = ({
  product = {},
  open,
  onClose,
  onAddFullfillment,
}) => {
  const { quantityRequested, quantityFulfilled } = product;
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { defaultSupplier } = useAuthContext();

  let maxFillableQuantity = product
    ? quantityRequested - quantityFulfilled
    : 999;

  const validationSchema = Yup.object().shape({
    supplierBusiness: Yup.mixed().required("Business is required"),
    quantity: Yup.number()
      .typeError("Must be a number")
      .min(1, "Minimum value is 1")
      .max(maxFillableQuantity, `Maximum value is ${maxFillableQuantity}`)
      .required("Required"),
  });

  const handleSubmit = async (values) => {
    return AdvancedRequestService.addProductFulfillment({
      advancedRequestProductId: product.id,
      supplierBusinessId: values.supplierBusiness.id,
      quantity: values.quantity,
    })
      .then((fulfillmentRecord) => {
        addSuccessAlert("Fulfillment saved");
        onAddFullfillment(fulfillmentRecord);
      })
      .catch((error) => {
        addErrorAlert("Error fulfilling product request", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} data-testid="fulfill-product-dialog">
      <DialogTitle>Fulfill Product Request</DialogTitle>
      <DialogContent>
        {product ? (
          <Formik
            initialValues={{
              supplierBusiness: defaultSupplier || null,
              quantity: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <Grid
                  container
                  columnSpacing={2}
                  rowGap={4}
                  alignContent="center"
                  justifyContent="space-evenly"
                >
                  <Grid>
                    <ProductInstanceSummarySupplierView
                      item={product}
                      elevation={3}
                    />
                  </Grid>
                  <Grid>
                    <Field
                      component={BusinessAutocomplete}
                      name="supplierBusiness"
                      label="Supplying Business"
                      size="medium"
                      suppliersOnly
                      required
                      onlyUsersBusinesses
                      sx={{ minWidth: 300 }}
                    />
                  </Grid>
                  <Grid>
                    <Field
                      component={FormQuantityField}
                      name="quantity"
                      label="Quantity to Fulfill"
                      sx={{ width: 150 }}
                      size="medium"
                    />
                  </Grid>
                </Grid>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                  <Button
                    onClick={onClose}
                    color="secondary"
                    data-testid="cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    data-testid="submit-button"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                          color="inherit"
                        />
                      ) : (
                        <Check />
                      )
                    }
                    disabled={isSubmitting}
                  >
                    Fulfill
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Skeleton variant="rectangular" height={200} />
            <Skeleton variant="rectangular" height={200} />
            <Skeleton variant="rectangular" height={200} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FulfillProductDialog;
