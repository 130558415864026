import { Box, FormHelperText, Typography } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React from "react";
import { useAlerts } from "../../common/context/AlertContext";
import BaseProductService from "../../common/service/BaseProductService";
import { displayPrice } from "../../common/util/DisplayUtil";

function determinePriceGroup(productInstance, priceGroups) {
  if (!priceGroups || priceGroups.length === 0) {
    return;
  }
  const attributes = productInstance.attributes;

  // A product must match 100% of the price group's attributes to be considered a match
  const matchingPriceGroups = priceGroups.filter((pg) => {
    return pg.instanceAttributes.every((attr) => {
      let matches = false;
      const productAttr = attributes.find((a) => a.name === attr.attributeName);
      if (productAttr) {
        matches = productAttr.value === attr.attributeValue;
      }
      return matches;
    });
  });

  if (matchingPriceGroups.length === 0) {
    return;
  }

  // Sort the matching price groups by the number of attributes they have
  // This way, the price group with the most attributes will be used
  matchingPriceGroups.sort(
    (a, b) => b.instanceAttributes.length - a.instanceAttributes.length
  );

  // If there are multiple matching price groups, use the first one
  return matchingPriceGroups[0];
}

export default function ProductPrice({
  baseProductId,
  name,
  fixedPrice,
  priceGroups,
  defaultPrice,
}) {
  const { addErrorAlert } = useAlerts();
  const [pricingGroups, setPricingGroups] = React.useState(priceGroups || []);
  const { values, setFieldValue, errors, touched } = useFormikContext();

  React.useEffect(() => {
    if (fixedPrice) {
      setPricingGroups([]);
    } else if (priceGroups) {
      setPricingGroups(priceGroups);
    } else if (baseProductId) {
      BaseProductService.getPriceGroupsByProductId(baseProductId)
        .then((pGroups) => {
          setPricingGroups(pGroups);
        })
        .catch((err) => {
          addErrorAlert("Error getting pricing groups", err);
        });
    }
  }, [baseProductId, addErrorAlert, fixedPrice, priceGroups]);

  React.useEffect(() => {
    const product = {
      attributes: [
        {
          name: "PrimaryColor",
          value: values.attributes.primaryColor,
        },
        {
          name: "SecondaryColor",
          value: values.attributes.secondaryColor,
        },
        {
          name: "Quality",
          value: values.attributes.quality,
        },
      ],
    };
    const priceGroup = determinePriceGroup(product, pricingGroups);
    if (priceGroup) {
      setFieldValue(name, priceGroup.price);
    } else {
      setFieldValue(name, defaultPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingGroups, defaultPrice, values.attributes]);

  const errorState = Boolean(getIn(touched, name) && getIn(errors, name));
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", justifyContent: "end", gap: 16 }}>
        <Typography>Unit Price:</Typography>
        <Typography data-testid={`${name}-product-price`}>
          {displayPrice(values[name])}
        </Typography>
      </Box>
      {Boolean(errorState) && (
        <FormHelperText error={errorState} sx={{ width: "100%" }}>
          {getIn(errors, name)}
        </FormHelperText>
      )}
    </Box>
  );
}
