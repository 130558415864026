import axios from "axios";

export const ERROR_LOG_BASE_URL = process.env.REACT_APP_API_PATH + "/errorLog";

const logAxiosInstance = axios.create();
logAxiosInstance.defaults.withCredentials = true;

const ErrorLogService = {
  async logError(error, message) {
    return logAxiosInstance.post(ERROR_LOG_BASE_URL, { error, message });
  },
};

export default ErrorLogService;
