import { Close, Receipt } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import * as Yup from "yup";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import SalesLogService from "../../common/service/SalesLogService";
import ManageSalesLogsSearchCriteria from "../Admin/ManageSalesLogs/ManageSalesLogsSearchCriteria";
import SalesLogSummary from "../Admin/ManageSalesLogs/SalesLogSummary";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";

export default function RecentSalesLogSearch() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { openExistingSalesLog } = usePointOfSaleContext();
  const defaultSearchCriteria = {
    invoiceNumber: "",
    page: 1,
    itemsPerPage: 25,
    buyerBusinesses: [],
    saleDate: null,
    productInSale: null,
    paymentMethod: null,
    excludeEmptySales: true,
    pickupLocation: null,
    status: "DRAFT",
  };

  React.useEffect(() => {}, []);

  return (
    <Box data-testid="recent-sales-log-search">
      <Button
        onClick={() => setDialogOpen(true)}
        size="medium"
        variant="outlined"
        startIcon={<Receipt />}
      >
        Open Invoice
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select a recent sale</DialogTitle>
        <DialogContent>
          <PaginatedSearchResults
            navigateOnSearch={false}
            fetchSearchResults={SalesLogService.getSalesLogs}
            defaultSearchCriteria={defaultSearchCriteria}
            SearchCriteriaComponent={ManageSalesLogsSearchCriteria}
            ResultDisplayComponent={SalesLogSummary}
            onClickSearchResult={(item) => {
              openExistingSalesLog(item);
              setDialogOpen(false);
            }}
            validationSchema={Yup.object().shape({
              invoiceNumber: Yup.string(),
              buyerBusinesses: Yup.array(),
              saleDate: Yup.date()
                .nullable()
                .typeError("Invalid Date")
                .test("4-digit-year-required", "Invalid Date", (value) => {
                  return value ? moment(value).year() > 1000 : true;
                }),
            })}
            normalizeSearchCriteria={(criteria) => {
              const cleanedCriteria = Object.fromEntries(
                Object.entries(criteria).filter(([_, v]) => v)
              );
              if (
                cleanedCriteria.saleDate &&
                typeof cleanedCriteria.saleDate !== "string"
              ) {
                cleanedCriteria.saleDate =
                  cleanedCriteria.saleDate.format("YYYY-MM-DD");
              }
              return cleanedCriteria;
            }}
          />
          <Button
            onClick={() => setDialogOpen(false)}
            size="large"
            sx={{ position: "fixed", top: "12px", right: "12px" }}
            color="secondary"
            endIcon={<Close />}
            variant="contained"
          >
            Back to Sale
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
