import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useCollective } from "../context/CollectiveContext";

export default function MainAppBar({ open, handleDrawerOpen, hideMenuButton }) {
  const { collectiveInfo } = useCollective();

  return (
    <AppBar position="fixed" open={open} color="primary" id="main-app-bar">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...((open || hideMenuButton) && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" sx={{ marginTop: "auto" }}>
          <Typography
            sx={{
              width: "fit-content",
              display: "inline-block",
              color: "#fff",
            }}
            variant="h1"
          >
            {collectiveInfo?.name}
          </Typography>
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}>
          <Typography variant="body2">Powered by </Typography>
          <img
            src="/img/picklinq-solutions/SVG/Picklinq_Horizontal_White.svg"
            alt="Picklinq Solutions"
            style={{ height: 48, marginLeft: 10 }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
