import React, { useState } from "react";

import { AddBox, Delete, Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormQuantityField } from "../../../common/components/form/FormQuantityField";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import AdvancedRequestService from "../../../common/service/AdvancedRequestService";
import { ProductInstanceSummarySupplierView } from "./AdvancedRequestSupplierView";

const EditFulfillProductDialog = ({
  product = {},
  open,
  onClose,
  onEditFullfillment,
}) => {
  const { quantityRequested, quantityFulfilled, quantityFulfilledByUser } =
    product;
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const { currentUser } = useAuthContext();

  const userSupplierBusinesses = currentUser?.businessRoles.filter(
    (business) => business.supplierStatus === "yes"
  );

  let maxFillableQuantity = product
    ? quantityRequested - quantityFulfilled + quantityFulfilledByUser
    : 999;
  const [existingFulfillments, setExistingFulfillments] = useState(null);
  const validationSchema = Yup.object().shape({
    fulfillments: Yup.array().of(
      Yup.object().shape({
        supplierBusiness: Yup.mixed()
          .required("Business is required")
          // Ensure that all selected businesses are unique
          .test(
            "unique-supplier-business",
            "Businesses must be unique",
            (value) => {
              const supplierBusinesses =
                formikRef.current.values.fulfillments.map(
                  (fulfillment) => fulfillment.supplierBusiness
                );
              return (
                supplierBusinesses.filter(
                  (business) => business?.id === value?.id
                ).length === 1
              );
            }
          ),
        quantity: Yup.number()
          .typeError("Must be a number")
          .min(1, "Minimum value is 1")
          .max(maxFillableQuantity, `Maximum value is ${maxFillableQuantity}`)
          .required("Required")
          // Ensure that the sum of the quantities fulfilled by the user is less than or equal to the quantity requested
          .test(
            "quantity-fulfilled",
            "Quantity exceeds requested amount",
            (value, context) => {
              let totalQuantityFulfilledByUser = 0;
              for (
                let i = 0;
                i < formikRef.current.values.fulfillments.length;
                i++
              ) {
                totalQuantityFulfilledByUser +=
                  parseInt(formikRef.current.values.fulfillments[i].quantity) ||
                  0;
              }

              if (totalQuantityFulfilledByUser > maxFillableQuantity) {
                return context.createError({
                  message: `Total quantity exceeds requested amount`,
                });
              }

              return true;
            }
          ),
      })
    ),
  });

  React.useEffect(() => {
    if (product && open) {
      setExistingFulfillments(null);
      AdvancedRequestService.getFulfillmentForProduct(product.id)
        .then(setExistingFulfillments)
        .catch((error) => {
          addErrorAlert("Error loading existing fulfillments", error);
        });
    } else {
      setExistingFulfillments(null);
    }
  }, [product, open, addErrorAlert]);

  const handleSubmit = async (values) => {
    return AdvancedRequestService.updateProductFulfillment(
      product.id,
      values.fulfillments
    )
      .then((updatedFulfillments) => {
        addSuccessAlert("Fulfillment changes saved");
        onEditFullfillment(updatedFulfillments);
      })
      .catch((error) => {
        addErrorAlert("Error fulfilling product request", error);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} data-testid="fulfill-product-dialog">
      <DialogTitle>Fulfill Product Request</DialogTitle>
      <DialogContent>
        {existingFulfillments ? (
          <Formik
            initialValues={{ fulfillments: existingFulfillments }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, isSubmitting, setFieldValue }) => {
              // If the user is part of a supplier that hasn't already fulfilled the request, they can add a fulfillment
              const canAddFulfillment = userSupplierBusinesses.some(
                (business) => {
                  const isBusinessAlreadyFulfilling = values.fulfillments.some(
                    (fulfillment) =>
                      fulfillment.supplierBusiness?.id === business.businessId
                  );
                  return !isBusinessAlreadyFulfilling;
                }
              );
              return (
                <Form autoComplete="off">
                  <Grid
                    container
                    columnSpacing={2}
                    rowGap={2}
                    alignContent="center"
                    justifyContent="space-evenly"
                  >
                    <Grid sx={{ mb: 2 }}>
                      <ProductInstanceSummarySupplierView
                        item={product}
                        elevation={3}
                      />
                    </Grid>

                    {values.fulfillments.map((fulfillment, index) => (
                      <Grid container xs={12} key={index} spacing={1}>
                        <Grid xs={12} sm={7}>
                          <Field
                            component={BusinessAutocomplete}
                            name={`fulfillments[${index}].supplierBusiness`}
                            label="Supplying Business"
                            size="medium"
                            suppliersOnly
                            required
                            onlyUsersBusinesses
                            disabled={Boolean(values.fulfillments[index].id)}
                          />
                        </Grid>
                        <Grid xs={8} sm="auto">
                          <Field
                            component={FormQuantityField}
                            name={`fulfillments[${index}].quantity`}
                            label="Quantity to Fulfill"
                            sx={{ width: 150 }}
                            size="medium"
                          />
                        </Grid>
                        <Grid>
                          <IconButton
                            onClick={async () => {
                              if (
                                window.confirm(
                                  "Are you sure you want to remove this fulfillment?"
                                )
                              ) {
                                try {
                                  if (values.fulfillments[index].id) {
                                    await AdvancedRequestService.deleteProductFulfillment(
                                      values.fulfillments[index].id,
                                      values.fulfillments[index]
                                        .supplierBusiness?.id
                                    );
                                  }
                                  addSuccessAlert("Fulfillment removed");

                                  const updatedFulfillments =
                                    values.fulfillments.filter(
                                      (_, i) => i !== index
                                    );
                                  setFieldValue(
                                    "fulfillments",
                                    updatedFulfillments
                                  );
                                  onEditFullfillment();
                                } catch (error) {
                                  addErrorAlert(
                                    "Error removing fulfillment",
                                    error
                                  );
                                }
                              }
                            }}
                            color="secondary"
                            data-testid={`remove-fulfillment-button-${index}`}
                            aria-label="Remove Fulfillment"
                            xs={2}
                            sm={1}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {canAddFulfillment && (
                    <Button
                      onClick={() =>
                        setFieldValue("fulfillments", [
                          ...values.fulfillments,
                          {
                            supplierBusiness: null,
                            quantity: "",
                          },
                        ])
                      }
                      startIcon={<AddBox />}
                    >
                      Add Fulfillment
                    </Button>
                  )}
                  <DialogActions
                    sx={{ justifyContent: "space-between", mt: 2 }}
                  >
                    <Button
                      onClick={onClose}
                      color="secondary"
                      data-testid="cancel-button"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      data-testid="submit-button"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress
                            style={{ width: "20px", height: "20px" }}
                            color="inherit"
                          />
                        ) : (
                          <Save />
                        )
                      }
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      Save Changes
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <>
            <Skeleton variant="rectangular" height={60} width={400} />
            <Skeleton variant="rectangular" height={80} width={400} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditFulfillProductDialog;
