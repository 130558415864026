import { Business, ShowChart, StackedBarChart } from "@mui/icons-material";
import {
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";

const ReportingHome = () => {
  const { isManagerOfCollective, isManagerOfAnySupplier, isManagerOfAnyBuyer } =
    useAuthContext();
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom>
        Reporting
      </Typography>
      <Grid container spacing={3}>
        {isManagerOfCollective && (
          <Grid xs={12} sm={6}>
            <Typography variant="h4" gutterBottom>
              Financial
            </Typography>
            <List size="large">
              <ListItemButton component={Link} to="supplier-totals">
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Supplier Totals" />
              </ListItemButton>
            </List>
          </Grid>
        )}
        <Grid xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Analytics
          </Typography>
          <List size="large">
            {(isManagerOfCollective || isManagerOfAnySupplier) && (
              <ListItemButton component={Link} to="supplier-snapshot">
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Supplier Snapshot" />
              </ListItemButton>
            )}
            {(isManagerOfCollective || isManagerOfAnySupplier) && (
              <ListItemButton
                component={Link}
                to="aged-out-report"
                data-testid="aged-out-report-link"
              >
                <ListItemIcon>
                  <StackedBarChart />
                </ListItemIcon>
                <ListItemText primary="Aged Out Report" />
              </ListItemButton>
            )}
            {(isManagerOfCollective ||
              isManagerOfAnySupplier ||
              isManagerOfAnyBuyer) && (
              <ListItemButton
                component={Link}
                to="sales-report"
                data-testid="sales-report-link"
              >
                <ListItemIcon>
                  <ShowChart />
                </ListItemIcon>
                <ListItemText primary="Sales Report" />
              </ListItemButton>
            )}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReportingHome;
