import React, { useMemo } from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import ReportContainer from "../../common/components/data-display/ReportContainer";
import SelectableBusinessDisplay from "../../common/components/data-display/SelectableBusinessDisplay";
import { useAuthContext } from "../../common/context/AuthContext";
import FinancialReportingService from "../../common/service/reporting/FinancialReportingService";
import SalesByWeekReportDisplay from "../Reporting/SalesReport/SalesByWeekReportDisplay";

export default function SupplierHome() {
  const { defaultSupplier, isManagerOfAnySupplier } = useAuthContext();
  const [selectedSupplier, setSelectedSupplier] =
    React.useState(defaultSupplier);
  const reportStartDate = useMemo(
    () => moment().subtract(3, "months").startOf("week").format("YYYY-MM-DD"),
    []
  );
  const reportEndDate = useMemo(
    () => moment().endOf("week").format("YYYY-MM-DD"),
    []
  );
  return (
    <Container
      style={{ textAlign: "center", marginTop: "20px" }}
      data-testid="supplier-home-container"
    >
      <Typography variant="h4" gutterBottom data-testid="supplier-home-title">
        Supplier Home
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={12}>
          <Grid container spacing={2} direction="column">
            <Grid>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="product-requests"
                style={{ marginTop: "20px" }}
                data-testid="fill-product-requests-button"
              >
                Fill Product Requests
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="upload-inventory"
                style={{ marginTop: "20px" }}
                data-testid="upload-inventory-button"
              >
                Upload Inventory
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="bring-to-market"
                style={{ marginTop: "20px" }}
                data-testid="bring-to-market"
              >
                What to Bring to Market
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isManagerOfAnySupplier && (
          <Grid xs={12} md={12}>
            <SelectableBusinessDisplay
              selectedBusiness={selectedSupplier}
              suppliersOnly
              onlyUsersBusinesses
              businessesUserManages
              setSelectedBusiness={setSelectedSupplier}
              allowChangingDefault
              sx={{ marginRight: "auto", marginLeft: "auto" }}
            />
            {selectedSupplier && (
              <Box
                sx={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}
              >
                <Typography variant="h6">Items Sold by Week</Typography>
                <ReportContainer
                  reportTitle="Sales by Week"
                  initialReportCriteria={{
                    startDate: reportStartDate,
                    endDate: reportEndDate,
                    supplier: selectedSupplier,
                    groupBy: "week",
                  }}
                  fetchReportData={FinancialReportingService.getSalesReport}
                  validationSchema={null}
                  ReportComponent={(reportProps) => (
                    <SalesByWeekReportDisplay
                      displayDataTable={false}
                      {...reportProps}
                    />
                  )}
                />
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
