import {
  Alert,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useFormik } from "formik";
import moment from "moment-timezone";
import React from "react";
import * as Yup from "yup";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityWithFulfillmentChip from "../../common/components/product/ProductQuantityWithFulfillmentChip";
import { useAlerts } from "../../common/context/AlertContext";
import {
  COLLECTIVE_PROPERTY,
  useCollective,
} from "../../common/context/CollectiveContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import { displayPrice } from "../../common/util/DisplayUtil";
import AttachmentPreviewDialog from "./AttachmentPreviewDialog";

export default function AdvancedRequestReview({
  advancedRequest,
  setFormikRef,
}) {
  const { getCollectiveProperty } = useCollective();
  const { addErrorAlert } = useAlerts();
  const [selectedAttachmentIdx, setSelectedAttachmentIdx] =
    React.useState(null);
  const errors = {};
  if (advancedRequest.requestProducts.length === 0) {
    errors.requestProducts = "Cannot submit an empty order";
  }

  const productRequestReviewInfo = getCollectiveProperty(
    COLLECTIVE_PROPERTY.PRODUCT_REQUEST_REVIEW_INFO
  );

  const formik = useFormik({
    initialValues: { ...advancedRequest, fulfillmentStatus: "ORDER_PLACED" },
    initialErrors: errors,
    validationSchema: Yup.object().shape({
      requestProducts: Yup.array().test(
        "products-required",
        "Cannot submit an empty order",
        (value) => {
          return value.length > 0;
        }
      ),
    }),
    onSubmit: async (requestToSave) => {
      if (advancedRequest.fulfillmentStatus === "ORDER_PLACED") {
        // The advanced request is already placed, so we don't need to update it
        return Promise.resolve(advancedRequest);
      }
      return AdvancedRequestService.submitAdvancedRequest(
        advancedRequest.id
      ).catch((err) => addErrorAlert("Error saving changes", err));
    },
  });

  const formikRef = React.useRef(formik);

  React.useEffect(() => {
    setFormikRef(formikRef);
  }, [setFormikRef]);

  return (
    <Grid container columnSpacing={2} rowGap={2} alignItems="start">
      <Grid xs={12} md={6} container alignItems="start" justifyContent="start">
        {Boolean(productRequestReviewInfo) && (
          <Grid xs={12} sx={{ p: 1, whiteSpace: "pre-wrap" }}>
            <Alert severity="info">{productRequestReviewInfo}</Alert>
          </Grid>
        )}
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Pickup Date:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {moment(advancedRequest.pickupDate).format("LL")}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Business:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {advancedRequest.buyerBusiness?.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 100, minWidth: 100 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Pickup:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {advancedRequest.pickupLocation ? (
                advancedRequest.pickupLocation.name
              ) : (
                <Typography variant="body1">Unknown</Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Notes:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">{advancedRequest.notes}</Typography>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Quantity:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1" data-testid="cart-item-count">
              {advancedRequest.requestProducts?.reduce(
                (acc, item) => acc + item.quantityRequested,
                0
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Total (est.):
            </Typography>
          </Grid>

          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {displayPrice(advancedRequest.estimatedTotal)}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container>
          {advancedRequest.attachments.map((attachment, idx) => (
            <Grid xs={6} sm={3} key={attachment.id}>
              <Card>
                <CardActionArea onClick={() => setSelectedAttachmentIdx(idx)}>
                  <CardMedia component="img" src={attachment.attachmentPath} />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          <AttachmentPreviewDialog
            open={selectedAttachmentIdx !== null}
            onClose={() => setSelectedAttachmentIdx(null)}
            imageUrl={
              advancedRequest.attachments?.[selectedAttachmentIdx]
                ?.attachmentPath
            }
            dialogTitle={`Reference Image ${selectedAttachmentIdx + 1}`}
            onClickNext={
              advancedRequest.attachments.length > 1
                ? () => {
                    setSelectedAttachmentIdx(
                      (selectedAttachmentIdx + 1) %
                        advancedRequest.attachments.length
                    );
                  }
                : undefined
            }
            onClickPrev={
              advancedRequest.attachments.length > 1
                ? () => {
                    setSelectedAttachmentIdx(
                      (selectedAttachmentIdx -
                        1 +
                        advancedRequest.attachments.length) %
                        advancedRequest.attachments.length
                    );
                  }
                : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        data-testid="request-product-list"
        sx={{ mt: 1 }}
        xs={12}
        md={6}
      >
        <Grid xs={12}>
          <Typography variant="h5" gutterBottom>
            Requested Products
          </Typography>
        </Grid>
        {advancedRequest?.requestProducts.map((product, index) => (
          <Grid xs={12} key={index}>
            <ProductInstanceSummary
              productInstance={product}
              summaryComponents={[ProductQuantityWithFulfillmentChip]}
            />
          </Grid>
        ))}
        {advancedRequest?.requestProducts.length === 0 && (
          <Grid xs={12}>
            <Alert severity="error">
              No products have been added to this request yet.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
