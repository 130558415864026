import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { getIn } from "formik";
import React from "react";

export default function FormSelect({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  options = [],
  label,
  size,
  renderOption,
  ...props
}) {
  const defaultValue = props.multiple ? [] : "";
  const errorState = Boolean(getIn(touched, name) && getIn(errors, name));
  const onChangeHandler = props.onChange || onChange;
  return (
    <FormControl
      fullWidth
      size={size || "small"}
      sx={{ ...props.sx }}
      error={errorState}
    >
      <InputLabel id={name + "-label"} error={errorState}>
        {label}
      </InputLabel>
      <Select
        labelId={name + "-label"}
        id={name}
        data-testid={name}
        label={label}
        fullWidth
        {...props}
        value={value || defaultValue}
        name={name}
        onChange={(e) => {
          onChangeHandler({ target: { name, value: e.target.value } });
        }}
        onBlur={onBlur}
        error={errorState}
      >
        {options.map((opt, i) => (
          <MenuItem value={typeof opt === "string" ? opt : opt.value} key={i}>
            {renderOption
              ? renderOption(opt, i)
              : typeof opt === "string"
                ? opt
                : opt.label}
          </MenuItem>
        ))}
      </Select>
      {(props.helperText || errorState) && (
        <FormHelperText error={errorState}>
          {props.helperText || getIn(errors, name)}
        </FormHelperText>
      )}
    </FormControl>
  );
}
